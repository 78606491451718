import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fractio-app';

  // TODO: Hassan - remove once demoed to the team
  constructor(private http: HttpClient) {
    this.http.get(environment.api.base + '/institutions').subscribe(response => {
      console.log('delta-response-v2: ', response);
    });
  }
}
